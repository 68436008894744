/* MAIN/CORE */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    height: 100%;
}

a,
button {
    font-size: 20px;
}

/* GLOBAL */
.hover-line {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 0 2px 2px 2px;
}

.hover-line::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
}

.hover-line:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


/* HEADER */
header {
    position: fixed;
    top: 0;
    background-color: transparent;
    transition: all 0.5s;
    z-index: 1000000;
}

header.scrolled {
    background-color: rgba(0, 0, 0, 0.3);
}

header .nav_wrapper {
    background-color: transparent;
}

nav.header_nav_menu {
    display: flex;
    background-color: transparent;
    justify-content: end;
    align-items: center;
    width: 100%;
}

header .left_side_menu {
    display: flex;
    justify-content: end;
    width: 40%;
    height: 100%;
    background-color: transparent;
}

header .center_side_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 20%;
    height: 100%;
}

header .center_side_menu img {
    width: 100%;
    max-height: 133px;
    object-fit: contain;
    transition: all 0.5s;
}

header.scrolled .center_side_menu img {
    max-height: 110px;
}

header .right_side_menu {
    display: flex;
    justify-content: space-between;
    width: 40%;
    height: 100%;
    background-color: transparent;
}

nav.header_nav_menu ul.nav_menu {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    padding: 0;
    margin: 0;
}

nav.header_nav_menu ul.nav_menu li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

nav.header_nav_menu ul.nav_menu li a {
    color: white;
    text-decoration: none;
}

nav.header_nav_menu ul.nav_menu li.apartments_sub_menu>.nav_submenu {
    position: relative;
    /* overflow: hidden; */
}

nav.header_nav_menu ul.nav_menu li.apartments_sub_menu>.nav_submenu>.nav_ul_submenu {
    position: absolute;
    background-color: red;
}

nav.header_nav_menu ul.nav_menu li.apartments_sub_menu>.nav_submenu:hover {
    overflow: visible;
}

/* LANGUAGE SWITCHER */
nav.header_nav_menu ul.nav_menu_language {
    position: relative;
    display: flex;
    justify-content: end;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

nav.header_nav_menu .lang_menu_wrapper {
    position: fixed;
    display: block;
    flex: 0 0 1;
    justify-self: end;
    background-color: rgba(0, 0, 0, 0.2);
    top: 20px;
    right: 0;
    border-radius: 10px 0 0 10px;
    padding: 6px 4px;
}

nav.header_nav_menu .lang_menu_wrapper ul.lang_menu {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
}

nav.header_nav_menu .lang_menu_wrapper ul.lang_menu li {
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.4s;
}

nav.header_nav_menu .lang_menu_wrapper ul.lang_menu li:not(li:last-child) {
    margin-bottom: 4px;
}

nav.header_nav_menu .lang_menu_wrapper ul.lang_menu li.lang_el_switcher:hover {
    background-color: #032896;
}

nav.header_nav_menu .lang_menu_wrapper ul.lang_menu li.lang_en_switcher:hover {
    background-color: #DD7E98;
}

nav.header_nav_menu .lang_menu_wrapper ul.lang_menu li.lang_bg_switcher:hover {
    background-color: #00966E;
}

nav.header_nav_menu .lang_menu_wrapper ul.lang_menu li button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: white;
    max-width: 40px;
    max-height: 40px;
    border: none;
    text-transform: uppercase;
    padding: 1px;
}

nav.header_nav_menu .lang_menu_wrapper ul.lang_menu li button img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}


@media (max-width: 1024px) {
    header .right_side_menu {
        justify-content: end;
        padding-right: 20px;
        margin: 0;
    }

    header .left_side_menu>.nav_menu {
        display: none !important;
    }

    header .right_side_menu>.nav_menu {
        display: none !important;
    }

    nav.header_nav_menu .lang_menu_wrapper ul.lang_menu {
        flex-direction: column;
    }

    nav.header_nav_menu .lang_menu_wrapper ul.lang_menu li {
        padding: 10px 0;
    }
}


/* HOME */
.home_body .first_section {
    position: relative;
}

.home_body .first_section .main_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}


/* PAGE 404 */
.error_message {
    background-color: red;
    height: 100%;
    color: white;
}


/* UNDER CONSTRUCTION PAGE */
.underConstructionPageWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0;
}

.underConstructionContent {
    max-width: 600px;
    background-color: #fff;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.underConstructionContent img {
    max-width: 100%;
    margin-bottom: 20px;
}

.underConstructionContent h1 {
    color: #333;
    font-size: 2rem;
    margin-bottom: 10px;
}

.underConstructionContent p {
    color: #666;
    font-size: 1rem;
    line-height: 1.6;
}